<template>
  <div id="app" class="container-fluid min-vh-100 d-flex flex-column">
    <router-view class="flex-grow-1"/>
  </div>
</template>
<script>
import {HomeDoor} from "@/index";

export default {
  name: "App",
  data() {
    return {
      Home: HomeDoor
    }
  },
  computed: {},
}
</script>
<style lang="less">
</style>
