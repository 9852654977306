import {AUTHORIZATION, TOKEN} from "@/api/config";
import Vue from "vue";
import router, {ROOT_URL} from "@/index";
import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

export async function getJson(url, params) {
    return await baseReq(url, "GET", params)
}

export async function postJson(url, data) {
    return await baseReq(url, "POST", null, data)
}

export async function postForm(url, data) {
    return await baseReq(url, "POST", null, data, {
        'Content-Type': 'multipart/form-data;'
    })
}

export async function baseReq(url, method, params, data = {}, headers = {},) {
    let res = await axios({
        method: method,
        url: url,
        params: params,
        data: data,
        headers: headers,
        timeout: 10000
    })
    return res.data
}

// 请求拦截器（在请求之前进行一些配置）
axios.interceptors.request.use(config => {
    if (localStorage.getItem(TOKEN)) {
        config.headers[AUTHORIZATION] = localStorage.getItem(TOKEN)
    }
    return config;
}, error => {
    return Promise.reject(error);
})

// 请求返回拦截器
axios.interceptors.response.use(response => {
    if (response.status === 200 && response.data != null) {
        if (response.data.code === 1) {
            return Promise.resolve(response);
        }
        if (response.data.code === 2) {
            Vue.prototype.$message.error(response.data.message)
            return Promise.resolve(response);
        }
        if (response.data.code === 401) {
            Vue.prototype.$message.error('登录已过期，请重新登录')
            localStorage.removeItem(TOKEN)
            router.push({path: ROOT_URL}).then(r => {
                return r;
            });
            return Promise.reject(response);
        }
        return Promise.resolve(response);
    }
    return Promise.reject(response);
}, error => {
    // 响应错误处理
    return Promise.reject(error);
})
